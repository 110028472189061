const formulas = {
  ls: {
    value: 'ls',
    title: 'booking.formula.ls.title',
    duration: 'booking.formula.ls.duration',
    subtitle: 'booking.formula.ls.subtitle',
    bullets: [
      'booking.formula.ls.bullets.1',
      'booking.formula.ls.bullets.2',
      'booking.formula.ls.bullets.3',
      'booking.formula.ls.bullets.4',
      'booking.formula.ls.bullets.5',
    ],
    description: 'booking.formula.ls.description',
    displayCmsKey: 'displayLongStay',
  },
  ms: {
    value: 'ms',
    title: 'booking.formula.ms.title',
    duration: 'booking.formula.ms.duration',
    subtitle: 'booking.formula.ms.subtitle',
    bullets: [
      'booking.formula.ms.bullets.1',
      'booking.formula.ms.bullets.2',
      'booking.formula.ms.bullets.3',
      'booking.formula.ms.bullets.4',
      'booking.formula.ms.bullets.5',
    ],
    description: 'booking.formula.ms.description',
    displayCmsKey: 'displayMediumStay',
  },
  day: {
    value: 'day',
    title: 'booking.formula.day.title',
    duration: 'booking.formula.day.duration',
    subtitle: 'booking.formula.day.subtitle',
    bullets: [
      'booking.formula.day.bullets.1',
      'booking.formula.day.bullets.2',
      'booking.formula.day.bullets.3',
    ],
    description: 'booking.formula.day.description',
    displayCmsKey: 'displayShortStay',
  },
};

export default formulas;
