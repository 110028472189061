import formulas from './formulas';

const stepsEnum = {
  formula: 'formula',
  dates: 'dates',
  customization: 'customization',
  tenant: 'tenant',
  guarantors: 'guarantors',
};

const steps = [
  {
    num: 0,
    step: 'formula',
    path: '/booking/formula',
    activeFor: [formulas.ls.value, formulas.ms.value, formulas.day.value],
  },
  {
    num: 1,
    step: 'dates',
    path: '/booking/dates',
    activeFor: [formulas.ls.value, formulas.ms.value, formulas.day.value],
  },
  {
    num: 2,
    step: 'customization',
    path: '/booking/customization',
    activeFor: [formulas.ls.value, formulas.ms.value, formulas.day.value],
  },
  {
    num: 3,
    step: 'tenant',
    path: '/booking/tenant',
    activeFor: [formulas.ls.value, formulas.ms.value, formulas.day.value],
  },
  {
    num: 4,
    step: 'guarantors',
    path: '/booking/guarantors',
    activeFor: [formulas.ls.value, formulas.day.value],
  },
];

export {
  steps,
  stepsEnum,
};
