import PropTypes from 'prop-types';

// Utils
import { cn } from 'utils';

import styles from './BurgerMenu.module.css';

const svgPaths = {
  line1: 'M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058',
  line2: 'M 20,50 H 80',
  line3: 'M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942',
};

function BurgerMenu({
  className, children, isOpened, setIsOpened,
}) {
  return (
    <>
      <button
        type="button"
        className={cn([styles.burger, isOpened ? styles.cross : '', className || ''])}
        onClick={() => setIsOpened(!isOpened)}
        aria-label="Menu"
      >
        <svg width="100" height="100" viewBox="0 0 100 100">
          <path className={cn([styles.line, styles.line1])} d={svgPaths.line1} />
          <path className={cn([styles.line, styles.line2])} d={svgPaths.line2} />
          <path className={cn([styles.line, styles.line3])} d={svgPaths.line3} />
        </svg>

      </button>

      <div className={cn([styles.menu, isOpened ? styles.opened : ''])}>
        {children}
      </div>
    </>
  );
}

BurgerMenu.propTypes = {
  className: PropTypes.string,
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  children: PropTypes.node,
};

BurgerMenu.defaultProps = {
  className: null,
  isOpened: false,
  setIsOpened: () => {},
  children: null,
};

export default BurgerMenu;
