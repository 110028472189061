import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

// Utils
import { cn } from 'utils';
// Styles
import styles from './NavItem.module.css';

function NavItem({ children, exact, href }) {
  const { pathname } = useRouter();
  const active = exact ? pathname === href : pathname.startsWith(href);

  return (
    <li tabIndex="-1" className={cn([styles.item, active ? styles.active : ''])}>
      {children}
    </li>
  );
}

NavItem.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

NavItem.defaultProps = {
  exact: false,
};

export default NavItem;
