import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Link } from 'next-translate-routes';

// Utils
import { cn } from 'utils';

// Hooks
import useResponsiveQueries from '../../../src/hooks/useResponsiveQueries';
import useBookingForm from '../../../src/hooks/useBookingForm';

// Images
import StaticLogo from '../../../public/images/logo/logo_horizontal.svg';
import StaticMobileLogo from '../../../public/images/logo/logo_ovelia.svg';

// Components
import { Picto } from '../../atoms/Picto/Picto';
import BurgerMenu from '../../atoms/BurgerMenu/BurgerMenu';
import Navigation from '../../molecules/Navigation/Navigation';

// Styles
import styles from './Header.module.css';

// Constants
import {
  CONCEPT_SLUG,
  CONTACT_SLUG,
  PHONE_NUMBER,
} from '../../../src/constants';

// Statics
import { steps, stepsEnum } from '../../../src/statics/steps';

const contexts = {
  default: 'default',
  booking: 'booking',
};

function Header({
  data: {
    accountLabel,
    accountLink,
    customButtonLink,
    customButtonLabel,
    logo,
    menu,
    phone,
  },
  context,
  sticky,
}) {
  const { t } = useTranslation();
  const {
    locale, pathname, events,
  } = useRouter();

  const [menuIsOpen, setMenuIsOpened] = useState(false);

  const { isMobile, isTablet } = useResponsiveQueries();

  const { bookingResidence } = useBookingForm();

  const isHandledDevice = isMobile || isTablet;
  const hideBookingBackToResidence = useMemo(() => {
    // hide back button for tenant step and all steps after
    const currentStep = steps.find((step) => step.path === pathname);
    const tenantStep = steps.find((step) => step.step === stepsEnum.tenant);
    return currentStep && tenantStep && currentStep.num >= tenantStep.num;
  }, [pathname]);

  useEffect(
    () => {
      events.on('routeChangeStart', () => {
        if (menuIsOpen) setMenuIsOpened(false);
      });

      return () => {
        events.off('routeChangeStart', () => {
          if (menuIsOpen) setMenuIsOpened(false);
        });
      };
    },
    [events, menuIsOpen],
  );

  return (
    <>
      <header className={cn([styles.header, sticky ? styles.sticky : ''])}>
        <div className={cn([styles.inner_header, 'd-flex a-center'])}>
          {context === contexts.booking ? (
            <Link href="/" className={styles.staticLogo}>
              {isHandledDevice ? <StaticMobileLogo /> : <StaticLogo />}
            </Link>
          )
            : logo?.url ? (
              <Link href="/" className={styles.logoLink} aria-label={t('header.home')}>
                <div className={styles.logoContainer}>
                  <Image
                    fill
                    src={logo?.url}
                    alt={logo?.description}
                    className={styles.logo}
                    sizes="256px"
                  />
                </div>
              </Link>
            ) : null}

          {context === contexts.booking
            && !isHandledDevice && !hideBookingBackToResidence
            && bookingResidence?.nameLong
            ? (
              <Link
                href={bookingResidence?.originUrl || '/'}
                className="d-flex a-center p2-desktop-bold font-color-black"
                aria-label={t('header.home')}
              >
                <Picto icon="chevronLeft" className="margin-right-8" />
                {bookingResidence?.nameLong}
              </Link>
            ) : null}

          <div
            className={cn([
              'd-flex a-center margin-left-auto',
              !isHandledDevice ? 'padding-left-40 gap-24' : '',
            ])}
          >
            {context === contexts.default && !isHandledDevice && menu?.length ? (
              <Navigation list={menu} type="desktop" />
            ) : null}

            {context === contexts.booking ? (
              <a href={`tel:${PHONE_NUMBER[locale]}`} className={styles.phone} aria-label={`${PHONE_NUMBER[locale]}`}>
                {!isHandledDevice ? (
                  <Picto icon="call" className="margin-right-8" />
                ) : null}
                {PHONE_NUMBER[locale]}
              </a>
            ) : null}

            {context === contexts.default && !isMobile && phone ? (
              <a href={`tel:${phone}`} className={styles.phone} aria-label={phone}>
                {!isMobile ? (
                  <Picto icon="call" className="margin-right-8" />
                ) : null}
                {phone}
              </a>
            ) : null}

            {context === contexts.default
            && (isTablet && !isMobile)
            && customButtonLink
            && customButtonLabel ? (
              <a href={customButtonLink} className={cn([styles.customButton, 'margin-left-16'])}>
                {customButtonLabel}
              </a>
              ) : null}

            {isTablet ? (
              <BurgerMenu
                isOpened={menuIsOpen}
                setIsOpened={() => setMenuIsOpened(!menuIsOpen)}
                className="margin-left-16"
              >
                {context === contexts.booking ? (
                  <Link href="/" className={styles.burgerLogo}>
                    {isHandledDevice ? <StaticMobileLogo /> : <StaticLogo />}
                  </Link>
                ) : logo?.url ? (
                  <Link href="/" className={cn([styles.logoLink, styles.burgerLogo])}>
                    <div className={styles.logoContainer}>
                      <Image
                        fill
                        src={logo?.url}
                        alt={logo?.description}
                        className={styles.logo}
                        sizes="256px"
                      />
                    </div>
                  </Link>
                ) : null}

                {context === contexts.default ? (
                  <Navigation list={menu} type="tablet" />
                ) : null}

                {context === contexts.booking && !hideBookingBackToResidence
                && bookingResidence?.nameLong
                  ? (
                    <Link href="/" className="d-flex a-center p2-desktop-bold font-color-black margin-top-16">
                      <Picto icon="chevronLeft" className="margin-right-8" />
                      {bookingResidence?.nameLong}
                    </Link>
                  ) : null}

                <div className={styles.infoItems}>
                  {phone ? (
                    <a
                      href={`tel:${context === contexts.booking ? PHONE_NUMBER[locale] : phone}`}
                      className={styles.phone}
                      aria-label={context === contexts.booking ? PHONE_NUMBER[locale] : phone}
                    >
                      {!isHandledDevice ? (
                        <Picto icon="call" className="margin-right-8" />
                      ) : null}
                      {context === contexts.booking ? PHONE_NUMBER[locale] : phone}
                    </a>
                  ) : null}

                  {context === contexts.booking ? (
                    <Link href={`/${CONCEPT_SLUG}`} className="p2-desktop-bold font-color-black">
                      {t('header.contactUs')}
                    </Link>
                  ) : null}

                  {context === contexts.default && accountLink && accountLabel ? (
                    <a href={accountLink} className="d-flex a-center gap-8 p2-desktop-bold font-color-black flex-auto-shrink">
                      <Picto icon="person" />
                      {accountLabel}
                    </a>
                  ) : null}

                  {context === contexts.default && customButtonLink && customButtonLabel ? (
                    <a href={customButtonLink} className={styles.customButton}>
                      {customButtonLabel}
                    </a>
                  ) : null}
                </div>
              </BurgerMenu>
            ) : null}

            {context === contexts.booking && !isTablet ? (
              <>
                <Link href={`${CONTACT_SLUG}`} className="p2-desktop-bold font-color-black">
                  {t('header.contactUs')}
                </Link>
                <span className={styles.separator} />
              </>
            ) : null}

            {((accountLink && accountLabel) || (customButtonLink && customButtonLabel))
              ? <div className={styles.separator} />
              : null}

            {context === contexts.default && !isTablet && accountLink && accountLabel ? (
              <a href={accountLink} className="d-flex a-center gap-8 p2-desktop-bold font-color-black flex-auto-shrink">
                <Picto icon="person" />
                {accountLabel}
              </a>
            ) : null}

            {context === contexts.default && !isTablet && customButtonLink && customButtonLabel ? (
              <a href={customButtonLink} className={styles.customButton}>
                {customButtonLabel}
              </a>
            ) : null}
          </div>
        </div>
      </header>

      {context === contexts.booking && isHandledDevice && bookingResidence?.nameLong ? (
        <div className={cn([styles.residence, 'p2-desktop-bold'])}>
          {bookingResidence?.nameLong}
        </div>
      ) : null}
    </>

  );
}

Header.propTypes = {
  data: PropTypes.shape({
    accountLabel: PropTypes.string,
    accountLink: PropTypes.string,
    customButtonLink: PropTypes.string,
    customButtonLabel: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
    }),
    menu: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
      }),
    })),
    phone: PropTypes.string,
  }),
  context: PropTypes.oneOf(Object.values(contexts)),
  sticky: PropTypes.bool,
  slugs: PropTypes.shape({}),
};

Header.defaultProps = {
  data: {
    accountLabel: '',
    accountLink: '',
    logo: {},
    menu: [],
    phone: '',
  },
  context: 'default',
  sticky: true,
  slugs: {},
};

export default Header;
